/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import * as React from "react";
import { Helmet } from "react-helmet";
import { termsOfServiceData as data } from "../data/terms-of-service-data";
import {
  StyledAnchor,
} from "../components/layoutElements";
import { COLORS, getFonts } from "../style/theme";
import { TextVariant } from "../components/typography";
import { Col, Container, Row } from "react-grid-system";
import { BrowserView, isMobile, MobileView } from "react-device-detect";

const BAR_SEPERATOR_PADDING_LEFT = 66;
const BAR_SEPERATOR_PADDING_LEFT_MOBILE = 28;

const TERMS_DATA = {
  title: "Our Terms of Service",
  heading: {
    sub_heading_one: "What's covered in these terms.",
    sub_heading_two: "It’s tempting to skip, but it’s important to establish what you can expect from us as you use InGood Labs, and what we expect from you.",
  },
  contents: {
    text_title: "Contents",
    sections: {
      introduction: {
        title: "Introduction",
        location: "introduction",
        body: "These Terms of Service (“Terms”) govern the relationship between you and InGood Labs, Inc., a Delaware corporation located at PO Box 246 Berwyn, PA, 19312 that operates the People and Community Platforms (the “Platforms”).  These Terms set out the rights and obligations of all users regarding use of the Platforms.  Your access to and use of the Platforms is conditioned on your acceptance of and compliance with these Terms.  These Terms apply to all visitors, users and others who access or use the Platforms.",
      },
      acceptanceOfTerms: {
        title: "Acceptance of Terms",
        location: "acceptance-of-terms",
        body: "If you do not agree to these Terms, you may not use the Platforms. If we make material changes to these Terms, we will let you know either through the Platforms or via email (at the email address associated with your profile). If you do not agree to those changes, you may send a request to cancel your account to info@ingoodlabs.com. If we do not receive a written request to cancel your account within ten days, you will be deemed as having accepted the revised terms.",
      },
      permittedUsers: {
        title: "Permitted Users",
        location: "permitted-users",
        body: "The Platforms are designed for use by adults. You must be thirteen years of age or older to use the Platforms. When you use the Platforms, you represent and warrant that you have the legal capacity to form a binding contract with us, and are doing so by your agreement to these Terms.",
      },
      communityGuidelines: {
        title: "Community Guidelines",
        location: "community-guidelines",
        body_title: "You must agree to the following Community Guidelines to utilize the Platforms:",
        guidelines: [
          {
            title: "Authentic Account",
            body: "You are required to provide us with accurate and up to date name and credential information related to your account on the Platforms. You may no timpersonate other individuals or create accounts for the purpose of misleading others or violating our Community Guidelines.",
          },
          {
            title: "Content",
            body: "Only post Content that is authentic and hasn’t been copied or collected from the Internet or that you do not have a right to post.",
          },
          {
            title: "Copyrighted Material",
            body: "If your Content contains any copyrighted material other than your own, you must have permission from the copyright owner(s). By uploading Content, you represent to us that you have obtained all such necessary permissions. Furthermore, by uploading your Content, you represent and warrant to us that: (a) you have the full right, power and authority to grant the rights described in these Terms; (b) your Content was created by you or you own or control all rights in your Content, such that you have all necessary licenses, rights, consents and permissions to submit and publish the Content and to grant us the rights to use it as set forth herein, including permission from all person(s) appearing in the Content and permission from all third parties whose materials are incorporated into the Content; (c) the Content and our use of it in accordance with our rights under these Terms does not and will not infringe on any other person or business’s trademark rights, copyrights, privacy or publicity rights, or other intellectual property rights or violate any applicable laws, rules or regulations.",
          },
          {
            title: "Photos and Videos",
            body: "Please do not upload or post any photos or videos other than your own unless you have the owner’s permission. If individuals other than you or your minor children appear in the photo or video, you must have permission to use the photo or video from the individuals (or their parent’s or legal guardian’s permission if a minor) captured in the photos or videos. ",
          },
          {
            title: "Harmful Content",
            body: "Your Content must not contain any worms, viruses, bots or other code which could harm, disrupt or compromise the functionality or security of our computer networks and systems.",
          },
          {
            title: "Respect other Members of the InGood Lab’s Community",
            body: "We reserve the right to remove Content that contains threats or hate speech, content that targets private individuals to degrade or shame them, personal information meant to blackmail or harass someone, repeated unwanted messages, or any other Content that we deem disrespectful and/or unacceptable. You may not use the Platforms to incite violence or attack anyone based on their race, ethnicity, national origin, sex, gender, gender identity, sexual orientation, religious affiliation, disabilities, medical conditions, genetic information, or diseases.",
          },
          {
            title: "Reporting Violations of Community Guidelines",
            body: "If you see something that you think may violate our Community Guidelines, please notify us by emailing info@ingoodlabs.com.",
          },
        ]
      },
      deactivatingYourAccount: {
        title: "Deactivating Your Account",
        location: "deactivating-your-account",
        body: "The Platforms are designed for use by adults. You must be thirteen years of age or older to use the Platforms. When you use the Platforms, you represent and warrant that you have the legal capacity to form a binding contract with us, and are doing so by your agreement to these Terms.",
      },
      rightToChangeOrTerminatePlatformsFunctionality: {
        title: "Right to Change or Terminate Platforms Functionality",
        location: "right-to-change-or-terminate-functionality",
        body: "We may at any time decide to alter, amend, modify, or terminate the Platforms, any functionality or portion of it, all in our sole discretion, and you understand that there is no guarantee that the Platforms or any portion or functionality of it will continue to operate or be available for any particular period of time.",
      },
      privacyPolicy: {
        title: "Privacy Policy",
        location: "privacy-policy",
        body:
          "When you set up an account with our Platforms, you will be asked to provide certain personal information, including your name and email address. We will store your personal information, but will not share it with any third parties without first letting you know.\n\n" + 
          "We will also share your personal information if necessary to provide the services offered, to monitor usage, to improve the Platforms and as otherwise described in these Terms. For example, we may store your personal information along with your files and data on a third-party server such as Amazon Web Services; or we may use a third-party analytics tool to learn how you are using the Platforms, and how we can improve that experience. We also use your personal information to send you notifications about the service and to respond to customer support requests. You can access and change your personal information by logging in to your account settings page or cancel your account by sending an email to info@ingoodlabs.com.\n\n" +
          "We may be required to disclose your personal information in order to: (1) comply with the law or legal process; (2) protect or defend our rights or property, or the rights or property of others; enforce these Terms; or (3) respond to claims that the content of any material on our system violates the rights of others.  If we must disclose your personal information in order to comply with the law or legal process, we will inform you (at the email address you provide) as soon as practicable, provided that it is lawful for us to do so.\n\n" +
          "We will also aggregate user information and perform statistical analyses of the collective behavior of our Platforms account holders and visitors, to measure overall demographics, and to analyze how to improve our service. We may share this information with third parties (such as Google Analytics), but such aggregate information does not contain personal information other than IP addresses. In addition, we may compile and disclose aggregate information about our users for promotional or other purposes.\n\n" +
          "We use session and login cookies, which help us keep track of when a person is logged in and determine the content or sites users link to and view, which services people use, and the content that you promote, block or delete. We will also capture login timestamps, and usage statistics, as well as IP addresses.\n\n" +
          "We will communicate with you via email, to the email address you provide to us. Some communications, such as emails informing you of new features, may be unsubscribed to via the unsubscribe link in the email. If you would like to cease all communications from us, please send an email to info@ingoodlabs.com and we will cancel your account.\n\n" +
          "If we are involved in a merger, acquisition or asset sale, your personal information may be transferred. We will provide notice to you, via the email address that you provide to us, before your personal information becomes subject to a different privacy policy.\n\n" +
          "The security of your personal information is important to us, but no method of transmission over the Internet, or method of electronic storage, is completely secure. While we strive to use commercially acceptable means to protect your personal information, we cannot guarantee its absolute security. If we believe your account has been breached we will promptly notify you of the breach at the email address that you provide (or by other legal means if we believe that your email account has been breached).\n\n" +
          "The site may contain links to other sites that are not operated by us. We have no control over, and assume no responsibility for, the content, privacy policies, or practices of any third-party site or service. We strongly advise that you review the privacy policy of every site you visit."
      },
      intellectualProperty: {
        title: "Intellectual Property",
        location: "intellectual-property",
        body:
          "You retain ownership of all content you post, upload to, or otherwise share on the Platforms. However, by posting, uploading, or sharing your Content, you grant us a non-exclusive, perpetual, sub-licensable, royalty-free, worldwide license to access, use, modify, reproduce and display your Content. This license continues when you delete the material or your account is deactivated or cancelled. This license permits us to perform the services that you registered for; for example, it permits us to store your Content on redundant data servers, but it does not transfer the copyright to us.  In addition, the Platforms provides features that allow you to share your Content with others. There are many things that others may do with that content, including copying it, modifying it, and re-sharing it. So, please consider carefully what you choose to share and with whom; we take no responsibility for that activity. Within these Terms, “your Content” means any video, text, sound, images, graphics or other material which you post, upload, or otherwise share on the Platforms\n\n" +
          "The selection, compilation, arrangement and presentation of all images and materials, and the overall design of the Platforms are copyrighted by us, and are protected by US and international laws. Use of our content without our express prior written permission is strictly prohibited.  The InGood Labs trademark may not be used in connection with any product or service without our express written permission. Within these Terms, “our content” means the copyrightable text, sound, images, graphics, and other material owned by InGood Labs, Inc. "
      },
      copyrightInfringement: {
        title: "Copyright Infringement",
        location: "copyright-infringement",
        text_paragraph_one: "We deal with copyright infringement in accordance with the Digital Millennium Copyright Act (DMCA).  You may not post, upload, or otherwise place any content or information on the site that belongs to a third party, unless you have the legal right to do so. If you believe that any such information or content has been posted on the site, please send a notice of copyright infringement containing the following information to the designated agent at the address below:",
        text_list_one: [
          "A physical or electronic signature of a person authorized to act on behalf of the owner of an exclusive right that is allegedly infringed.",
          "Identification of the copyrighted work claimed to have been infringed, or, if multiple copyrighted works are covered by a single notification, a representative list of such works.",
          "Identification of the material that is claimed to be infringing or to be the subject of infringing activity and that is to be removed or access to which is to be disabled, and information reasonably sufficient to permit us to locate the material.",
          "Contact information for the notifying party, including name, address, telephone number, and email address.",
          "A statement that you have a good faith belief that use of the material in the manner complained of is not authorized by the copyright owner, its agent, or the law.",
          "A statement that the information in the notification is accurate, and under penalty of perjury, that you are authorized to act on behalf of the owner of an exclusive right that is allegedly infringed.",
        ],
        text_paragraph_two: "Upon notification of claimed infringement, we will respond expeditiously to remove, or disable access to, the material that is claimed to be infringing or to be the subject of infringing activity. We will also notify the person who posted, uploaded, or otherwise placed the allegedly infringing material on the site that we have removed or disabled access to such material.  If you believe that material has been removed improperly, you must send a written counter notification to the agent, and include:",
        text_list_two: [
          "A physical or electronic signature.",
          "Identification of the material that has been removed or to which access has been disabled and the location at which the material appeared before it was removed or access to it was disabled.",
          "A statement under penalty of perjury that you have a good faith belief that the material was removed or disabled as a result of mistake or misidentification.",
          "Your name, address, and telephone number, and a statement that you consent to the jurisdiction of Federal District Court for the judicial district in which the address is located, or if your address is outside of the United States, for any judicial district in which we may be found, and that you will accept service of process from the person who provided the original notification or an agent of such person.",
        ],
        text_paragraph_three:
          "Upon receipt of a counter notification complying with these requirements, we will promptly provide the original reporter with a copy of the counter notification, and inform that person that we may replace the removed material or cease disabling access to it if we do not receive notice within ten business days that the original reporter is seeking a court order to prevent further infringement of the material at issue.\n\n" +
          "Designated Agent:\n\n\n" +
          "InGood Labs, Inc.\n" +
          "PO Box 246 Berwyn, PA, 19312\n" +
          "info@ingoodlabs.com"
      },
      disclaimerOfWarranties: {
        title: "Disclaimer of Warranties",
        location: "disclaimer-of-warranties",
        body:
          "We will strive to prevent interruptions to accessing the Platforms and be good stewards of your content. However, the Platforms is provided on an “as is” and “as available” basis. We disclaim all warranties of any kind, whether express or implied, including without limitation any warranty of merchantability, fitness for a particular purpose, or non-infringement. We do not make any warranty that the Platforms will meet your requirements, or that access to the Platforms will be uninterrupted, timely, secure, or error free, or that defects, if any, will be corrected. You understand that you access content or services through the Platforms at your own discretion and risk.",
      },
      limitationOfLiability: {
        title: "Limitation of Liability",
        location: "limitation-of-liability",
        text_paragraph_one: "Under no circumstance -including, without limitation, negligence- shall we be liable for any direct, indirect, incidental, special or consequential damages, resulting from:",
        text_list_one: [
          "the use or the inability to the platforms",
          "the cost of procurement of substitute goods or services",
          "loss of profits, use, data, or other intangibles, even if we have been advised of the possibility of such damages.",
        ],
        text_paragraph_two: "The foregoing shall not apply to the extent prohibited by law.",
      },
      indemnification: {
        title: "Indemnification",
        location: "indemnification",
        body:
          "You agree to indemnify, defend, and hold harmless us, our officers, directors, employees, stockholders, partners, agents, and suppliers, and their respective affiliates, officers, directors, employees, members, stockholders, partners, and agents, from any and all claims and expenses, including attorneys’ fees, arising out of your use of the Platforms, including but not limited to your violation of these Terms. We may, at our sole discretion, assume the exclusive defense and control of any matter subject to indemnification by you. The assumption of such defense or control by us, however, shall not excuse any of your indemnity obligations.",
      },
      termination: {
        title: "Termination",
        location: "termination",
        body:
          "Should you violate these Terms or Community Guidelines, we reserve the right to terminate your User Account.",
      },
      thirdPartySoftwarePolicies: {
        title: "Third-Party Software Policies",
        location: "third-party-software-policies",
        body:
          "InGood Labs may use or link to third-party software that is not owned or controlled by us and you are subject to the terms and policies of these third-party software providers.  We have no control over, and assume no responsibility for, the content, privacy policies, or practices of any third party web sites or services. We shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with the use of or reliance on any such content, goods or services available on or through any such web sites or services.  We strongly advise you to read the terms and conditions and privacy policies of any third-party web sites or services that you visit.",
      },
      generalTerms: {
        title: "General Terms",
        location: "general-terms",
        body:
          "These Terms shall be governed by and construed in accordance with the laws of the Commonwealth of Pennsylvania, without giving effect to its conflict of law provisions. You agree that you will bring any claim or cause of action arising out of your use of the site in the courts located within Pennsylvania and you also agree to submit to the personal and exclusive jurisdiction of those courts. You agree that any claim or cause of action arising out of your use of the Platforms or these Terms must be filed within one year after such claim or cause of action arose or it shall be forever barred, notwithstanding any statute of limitations or other law to the contrary. If any provision contained in these Terms is determined unenforceable, then such provision will be severed and replaced with a new provision that most closely reflects the intent of the original provision, and the remaining provisions of these Terms will remain in full force and effect. No waiver of any provision of these Terms shall be effective except pursuant to a written instrument signed by us expressly waiving compliance, and any such waiver shall be effective only in the specific instance and for the specific purpose stated in such writing. You agree that no joint venture, partnership, employment, or agency relationship exists between you and us as a result of these Terms and/or your use of the Platforms. You may not assign any right, interest, or benefit provided under these Terms or through the Platforms without our express prior written consent. These Terms set forth the entire agreement between you and us, and supersede any and all prior communications, agreements and proposals, whether electronic, oral or written, between you and us with respect to the Platforms.",
      },
      updates: {
        title: "Updates",
        location: "updates",
        body:
          "We reserve the right to amend this Agreement at any time. However, we will make every effort to not change it very often, and if we do make a material change, we will inform you or provide public notice on our website. This Agreement was last modified on August 12, 2021.",
      },
      downloadThePdf: {
        title: "Download the PDF",
        location: "download-the-pdf",
        text_body: [
        ],
      },
    },
  },
};

function getStyles() {
  const fonts = getFonts();
  const desktop = {
    heading: {
      title_text: {
        ...fonts.heading,
        color: COLORS.shipGray,
        textAlign: "center",
      },
      body_text: {
        ...fonts.heading_body,
        color: COLORS.shipGray,
        textAlign: "center",
        whiteSpace: "pre-wrap",
      },
      body_text_row: { }
    },
    contents: {
      section: {
        backgroundColor: COLORS.oldLace,
        paddingBottom: 72,
        paddingTop: 72,
      },
      title_text: {
        ...fonts.section,
        color: COLORS.shipGray,
      },
      link_text: {
        ...fonts.link,
        color: COLORS.saddleBrown,
        textTransform: "capitalize",
        textDecoration: "underline",
        textUnderlineOffset: "3px",
      },
      list: {
        columnCount: 2,
        columnGap: 20,
        listStyle: "none",
        paddingLeft: 0,
      },
      list_item: {
        borderLeft: `3px solid ${COLORS.silverSand}`,
        paddingLeft: BAR_SEPERATOR_PADDING_LEFT,
        maxWidth: 347,
        paddingBottom: 5,
      },
    },
    sections: {
      title_text: {
        ...fonts.section,
        color: COLORS.shipGray,
      },
      body_text: {
        ...fonts.section_body,
        color: COLORS.shipGray,
        whiteSpace: "pre-wrap",
      },
      body_title_text: {
        ...fonts.sub_section,
        color: COLORS.shipGray,
      },
      section: {
        paddingBottom: 15,
        paddingTop: 15,
      },
      section_row: { },
      body_div: {
        borderLeft: `3px solid ${COLORS.silverSand}`,
        paddingLeft: BAR_SEPERATOR_PADDING_LEFT,
      },
      list_item: {
        ...fonts.section_body,
        color: COLORS.shipGray,
      },
    }
  }

  const mobile = {
    ...desktop,
    heading: {
      ...desktop.heading,
      body_text_row: {
        paddingLeft: '36px',
        paddingRight: '36px',
      }
    },
    contents: {
      ...desktop.contents,
      section: {
        backgroundColor: COLORS.oldLace,
        paddingBottom: 48,
        paddingTop: 48,
      },
      list: {
        columnCount: 1,
        listStyle: "none",
        paddingLeft: 0,
      },
      list_item: {
        borderLeft: `3px solid ${COLORS.silverSand}`,
        paddingLeft: BAR_SEPERATOR_PADDING_LEFT_MOBILE,
        maxWidth: 275,
        paddingBottom: 5,
      },
    },
    sections: {
      ...desktop.sections,
      section_row: {
        paddingLeft: '14px',
       },
      section: {
        paddingBottom: '10px',
        paddingTop: '10px',
      },
      body_div: {
        borderLeft: `3px solid ${COLORS.silverSand}`,
        paddingLeft: BAR_SEPERATOR_PADDING_LEFT_MOBILE,
      },
    }
  };

  return isMobile ? mobile : desktop;
}

const TermsHeading: React.FC = () => {
  const styles = getStyles();
  return (
    <>
      <Row align="center" justify="center">
        <h1 css={styles.heading.title_text}>{TERMS_DATA.title}</h1>
      </Row>
      <Row css={styles.heading.body_text_row} align="center" justify="center">
        <div>
          <Row align="center" justify="center">
            <p css={styles.heading.body_text}>{TERMS_DATA.heading.sub_heading_one}</p>
          </Row>
          <Row>
            <p css={styles.heading.body_text}>{"\n"}</p>
          </Row>
          <Row align="center" justify="center">
            <Col sm={11}>
              <p css={styles.heading.body_text}>{TERMS_DATA.heading.sub_heading_two + "\n\n"}</p>
            </Col>
          </Row>
        </div>
      </Row>
    </>
  );
}

const TermContents = () => {
  const styles = getStyles();
  if (typeof window == "undefined") {
    return <></>;
  }
  return (
    <section css={styles.contents.section}>
      <Container>
        <Row align="center" justify="center">
          <Col sm={10}>
            <div></div>
            <h2 css={styles.contents.title_text}>
              {TERMS_DATA.contents.text_title}
            </h2>
            <ul css={styles.contents.list}>
              {Object.values(TERMS_DATA.contents.sections).map((link, index) => (
                  <li key={"link" + index} css={styles.contents.list_item}>
                    <a href={
                        window.location.origin +
                        window.location.pathname +
                        "#" +
                        link.location
                      }
                    >
                      <p css={styles.contents.link_text}>
                        {link.title}
                      </p>
                    </a>
                  </li>
                ))}
            </ul>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

const TermsSections = () => {
  const styles = getStyles()

  interface SingleParagraphProps {
    location: string;
    title: string;
    body: string;
  }
  const OnlyText: React.FC<SingleParagraphProps> = (props) => {
    return (
      <section
        css={styles.sections.section}
        id={props.location}
      >
        <Container>
          <Row css={styles.sections.section_row} align="center" justify="center">
            <Col sm={10}>
              <h1 css={styles.sections.title_text}>
                {props.title}
              </h1>
              <div css={styles.sections.body_div}>
                <p css={styles.sections.body_text}>
                  {props.body}
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    );
  }

  const Introduction: React.FC = () => <OnlyText
    location={TERMS_DATA.contents.sections.introduction.location}
    title={TERMS_DATA.contents.sections.introduction.title}
    body={TERMS_DATA.contents.sections.introduction.body}
  />;

  const AcceptanceOfTerms: React.FC = () => <OnlyText
    location={TERMS_DATA.contents.sections.acceptanceOfTerms.location}
    title={TERMS_DATA.contents.sections.acceptanceOfTerms.title}
    body={TERMS_DATA.contents.sections.acceptanceOfTerms.body}
  />;

  const PermittedUsers: React.FC = () => <OnlyText
    location={TERMS_DATA.contents.sections.permittedUsers.location}
    title={TERMS_DATA.contents.sections.permittedUsers.title}
    body={TERMS_DATA.contents.sections.permittedUsers.body}
  />;

  const DeactivatingYourAccount: React.FC = () => <OnlyText
    location={TERMS_DATA.contents.sections.deactivatingYourAccount.location}
    title={TERMS_DATA.contents.sections.deactivatingYourAccount.title}
    body={TERMS_DATA.contents.sections.deactivatingYourAccount.body}
  />;

  const RightToChangeOrTerminate: React.FC = () => <OnlyText
    location={TERMS_DATA.contents.sections.rightToChangeOrTerminatePlatformsFunctionality.location}
    title={TERMS_DATA.contents.sections.rightToChangeOrTerminatePlatformsFunctionality.title}
    body={TERMS_DATA.contents.sections.rightToChangeOrTerminatePlatformsFunctionality.body}
  />;

  const DisclaimerOfWarranties: React.FC = () => <OnlyText
    location={TERMS_DATA.contents.sections.disclaimerOfWarranties.location}
    title={TERMS_DATA.contents.sections.disclaimerOfWarranties.title}
    body={TERMS_DATA.contents.sections.disclaimerOfWarranties.body}
  />;

  const Indemnification: React.FC = () => <OnlyText
    location={TERMS_DATA.contents.sections.indemnification.location}
    title={TERMS_DATA.contents.sections.indemnification.title}
    body={TERMS_DATA.contents.sections.indemnification.body}
  />;

  const Termination: React.FC = () => <OnlyText
    location={TERMS_DATA.contents.sections.termination.location}
    title={TERMS_DATA.contents.sections.termination.title}
    body={TERMS_DATA.contents.sections.termination.body}
  />;

  const ThirdPartySoftwarePolicies: React.FC = () => <OnlyText
    location={TERMS_DATA.contents.sections.thirdPartySoftwarePolicies.location}
    title={TERMS_DATA.contents.sections.thirdPartySoftwarePolicies.title}
    body={TERMS_DATA.contents.sections.thirdPartySoftwarePolicies.body}
  />;

  const GeneralTerms: React.FC = () => <OnlyText
    location={TERMS_DATA.contents.sections.generalTerms.location}
    title={TERMS_DATA.contents.sections.generalTerms.title}
    body={TERMS_DATA.contents.sections.generalTerms.body}
  />;

  const Updates: React.FC = () => <OnlyText
    location={TERMS_DATA.contents.sections.updates.location}
    title={TERMS_DATA.contents.sections.updates.title}
    body={TERMS_DATA.contents.sections.updates.body}
  />;

  const PrivacyPolicy: React.FC = () => <OnlyText
    location={TERMS_DATA.contents.sections.privacyPolicy.location}
    title={TERMS_DATA.contents.sections.privacyPolicy.title}
    body={TERMS_DATA.contents.sections.privacyPolicy.body}
  />;

  const IntellectualProperty: React.FC = () => <OnlyText
    location={TERMS_DATA.contents.sections.intellectualProperty.location}
    title={TERMS_DATA.contents.sections.intellectualProperty.title}
    body={TERMS_DATA.contents.sections.intellectualProperty.body}
  />;

  const CommunityGuidelines: React.FC = () => {
    return (
      <section
        css={styles.sections.section}
        id={TERMS_DATA.contents.sections.communityGuidelines.location}
      >
        <Container>
          <Row align="center" justify="center">
            <Col sm={10}>
              <h1 css={styles.sections.title_text}>
                {TERMS_DATA.contents.sections.communityGuidelines.title}
              </h1>
              <div css={styles.sections.body_div}>
                <p css={styles.sections.body_text}>
                  {TERMS_DATA.contents.sections.communityGuidelines.body_title}
                </p>
                {TERMS_DATA.contents.sections.communityGuidelines.guidelines.map((guideline) =>
                  <>
                    <h2 css={styles.sections.body_title_text}>
                      {guideline.title}
                    </h2>
                    <p css={styles.sections.body_text}>
                      {guideline.body}
                    </p>
                  </>
                )}
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    );
  }

  const CopyrightInfringement: React.FC = () => {
    return (
      <section
        css={styles.sections.section}
        id={TERMS_DATA.contents.sections.copyrightInfringement.location}
      >
        <Container>
          <Row align="center" justify="center">
            <Col sm={10}>
              <h1 css={styles.sections.title_text}>
                {TERMS_DATA.contents.sections.copyrightInfringement.title}
              </h1>
              <div css={styles.sections.body_div}>
                <p css={styles.sections.body_text}>
                  {TERMS_DATA.contents.sections.copyrightInfringement.text_paragraph_one}
                </p>
                <ul>
                  {TERMS_DATA.contents.sections.copyrightInfringement.text_list_one.map((item) =>
                    <li css={styles.sections.list_item}>
                      {item}
                    </li>
                  )}
                </ul>

                <p css={styles.sections.body_text}>
                  {"\n" + TERMS_DATA.contents.sections.copyrightInfringement.text_paragraph_two}
                </p>
                <ul>
                  {TERMS_DATA.contents.sections.copyrightInfringement.text_list_two.map((item) =>
                    <li css={styles.sections.list_item}>
                      {item}
                    </li>
                  )}
                </ul>

                <p css={styles.sections.body_text}>
                  {"\n" + TERMS_DATA.contents.sections.copyrightInfringement.text_paragraph_three}
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    );
  }

  const LimitationOfLiability: React.FC = () => {
    return (
      <section
        css={styles.sections.section}
        id={TERMS_DATA.contents.sections.limitationOfLiability.location}
      >
        <Container>
          <Row align="center" justify="center">
            <Col sm={10}>
              <h1 css={styles.sections.title_text}>
                {TERMS_DATA.contents.sections.limitationOfLiability.title}
              </h1>
              <div css={styles.sections.body_div}>
                <p css={styles.sections.body_text}>
                  {TERMS_DATA.contents.sections.limitationOfLiability.text_paragraph_one}
                </p>
                <ol>
                  {TERMS_DATA.contents.sections.limitationOfLiability.text_list_one.map((item) =>
                    <li css={styles.sections.list_item}>
                      {item}
                    </li>
                  )}
                </ol>

                <p css={styles.sections.body_text}>
                  {TERMS_DATA.contents.sections.limitationOfLiability.text_paragraph_two}
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    );
  }

  const DownloadPdf: React.FC = () => {
    return (
      <section
        css={styles.sections.section}
        id={TERMS_DATA.contents.sections.downloadThePdf.location}
      >
        <Container>
          <Row align="center" justify="center">
            <Col sm={10}>
              <h1 css={styles.sections.title_text}>
                {TERMS_DATA.contents.sections.downloadThePdf.title}
              </h1>
              <div css={styles.sections.body_div}>
                <p css={styles.sections.body_text}>
                  <>
                    Effective{" "}
                    <span>
                      August 12, 2021
                    </span>{" "}
                    <StyledAnchor
                      variant={TextVariant.small_D}
                      href={"/terms.pdf"}
                      download
                    >
                      Download PDF
                    </StyledAnchor>
                  </>
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    );
  }

  const EmptySection: React.FC = () => {
    return (
      <section
        css={styles.sections.section}
      ></section>
    );
  };

  return (
    <>
      <Introduction />
      <AcceptanceOfTerms />
      <PermittedUsers />
      <CommunityGuidelines />
      <DeactivatingYourAccount />
      <RightToChangeOrTerminate />
      <PrivacyPolicy />
      <IntellectualProperty />
      <CopyrightInfringement />
      <DisclaimerOfWarranties />
      <LimitationOfLiability />
      <Indemnification />
      <Termination />
      <ThirdPartySoftwarePolicies />
      <GeneralTerms />
      <Updates />
      <DownloadPdf />
      <EmptySection />
    </>
  )
}

const TermsOfService: React.FC = () => {
  return (
    <>
      <Helmet>
        <title>{data.title}</title>
      </Helmet>
      <main
        css={css({
          backgroundColor: COLORS.alabaster,
        })}
      >
        <TermsHeading />
        <TermContents />
        <TermsSections />
      </main>
    </>
  );
}

export default TermsOfService;
